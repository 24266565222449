import { IoAddCircleOutline } from "react-icons/io5";

export const navAnchorLinks = [
    {
        link: "https://organizer.eventfullsunday.co.uk/login",
        target: "",
        rel: "",
        title: "Create",
        icon: IoAddCircleOutline,
        fontType: 'font-normal'
    },
    {
        link: "https://events.eventfullsunday.co.uk/",
        target: "_blank",
        rel: "noopener noreferrer",
        title: "BLOGS"
    }
];

export const navRouterLinks = [
    {
        link: "/register",
        title: "SIGNUP"
    },
    {
        link: "/login",
        title: "LOGIN"
    }
];